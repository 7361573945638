.mint-container {
  display: flex;
  gap: 24px;
  max-width: 1200px;
  margin: 0 auto;
  padding: 24px;
}

.left-column {
  flex: 1;
  max-width: 50%;
}

.right-column {
  display: flex;
  flex-direction: column;
  gap: 20px; /* Adjust this value as needed */
}

.card {
  background-color: var(--card-background);
  border-radius: 8px; /* Updated to 8px */
  padding: 24px;
  box-shadow: 14px 14px 0 -4px gold, 14px 14px 0 0 black;
  border: 4px solid black; /* Updated border */
  flex: 1;
  transition: box-shadow 0.3s ease, transform 0.3s ease;
}

.card:hover {
  box-shadow: 16px 16px 0 -4px gold, 16px 16px 0 0 black;
  transform: translate(-2px, -2px);
}

.card h2 {
  font-family: var(--font-family);
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 16px;
  color: var(--text-primary);
}

.nft-collection-info {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.collection-image {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-right: 12px;
}

.user-nfts {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  gap: 12px;
  margin-bottom: 16px;
}

.nft-placeholder {
  border: 2px solid var(--border-color);
  border-radius: 2px; /* Reduced for a more square look */
  padding: 8px;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 2px 2px 0 var(--shadow-color);
  transform: translateY(-2px); /* Always appear slightly lifted */
}

.nft-placeholder:hover {
  box-shadow: 3px 3px 0 var(--shadow-color);
  transform: translateY(-3px); /* Lift slightly more on hover */
}

.nft-placeholder.selected {
  border-color: var(--accent-color);
  box-shadow: 3px 3px 0 var(--accent-color);
}

.nft-image {
  width: 100%;
  height: auto;
  border-radius: 4px;
}

.nft-actions {
  display: flex;
  gap: 8px;
  margin-top: 16px;
}

.input-group {
  flex: 1;
  margin-bottom: 0; /* Remove bottom margin as it's handled by .mint-inputs */
  box-sizing: border-box; /* Add this to include padding in the width calculation */
}

.input-group label {
  font-family: var(--font-family);
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
  color: var(--text-secondary);
}

.input-group input,
.input-group select {
  font-family: var(--font-family);
  width: 100%;
  padding: 8px 12px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  font-size: 16px;
  box-sizing: border-box;
}

button {
  font-family: var(--font-family);
  background-color: var(--accent-color);
  color: white;
  border: none;
  padding: 10px 16px;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: var(--accent-hover);
}

.mint-button {
  width: 100%;
  margin-top: 16px;
}

.mint-stablecoins {
  display: flex;
  flex-direction: column;
}

.mint-inputs {
  display: flex;
  gap: 16px;
  margin-bottom: 16px;
}

@media (max-width: 768px) {
  .mint-container {
    flex-direction: column;
  }
  
  .mint-inputs {
    flex-direction: column;
  }

  .input-group {
    width: 100%; /* Ensure full width on smaller screens */
  }
}

.input-group input.no-spinner {
  -moz-appearance: textfield; /* Firefox */
}

.input-group input.no-spinner::-webkit-outer-spin-button,
.input-group input.no-spinner::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.transaction-status {
  margin-top: 16px;
  padding: 16px;
  border-radius: 4px;
  color: #000000; /* Black text for better visibility on all backgrounds */
}

.transaction-status h3 {
  margin-top: 0;
  margin-bottom: 8px;
  font-size: 18px;
}

.transaction-status p {
  margin: 0;
}

.highlighted-max-mintable {
  color: red;
  font-weight: bold;
  transition: all 0.3s ease;
}

.repay-actions {
  display: flex;
  gap: 10px;
  margin-top: 16px;
}

.approve-button, .repay-button {
  flex: 1;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.approve-button {
  background-color: #4CAF50;
  color: white;
}

.approve-button:hover {
  background-color: #45a049;
}

.repay-button {
  background-color: #008CBA;
  color: white;
}

.repay-button:hover {
  background-color: #007B9E;
}