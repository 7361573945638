/* Copy the contents of the previous styles.css file here */
@import url(https://fonts.googleapis.com/css?family=New+Amsterdam);

:root {
    --primary-color: #ff3366;
    /* Pink-red */
    --secondary-color: #0066ff;
    /* Sharp blue */
    --text-color: #333333;
    /* Darker text color for better contrast */
    --card-bg-color: #ffffff;
    /* Changed from #f0f0f0 to white */
    --button-color: #ff3366;
    --button-hover-color: #ff1a4d;
    --menu-text-color: #ffffff;
    --site-background: #f3f4f6;
    /* Slightly darker background for the entire site */
    --border-color: #121212;
    /* Almost black border color */
    --shadow-color: rgba(0, 0, 0, 0.5);
    /* Black shadow with 50% opacity */
    /* --font-family: 'New Amsterdam', cursive; */
    font-family: "New Amsterdam", sans-serif;
    font-weight: 400;
    font-style: normal;
}


body {
    background-color: var(--site-background);
    font-family: "New Amsterdam", sans-serif;
    margin: 0;
    padding: 0;
    color: var(--text-color);
    min-height: 100vh;
}

header {
    background-color: rgba(0, 0, 0, 0.8);
    /* Darker background */
    padding: 1rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
}

.logo {
    height: 40px;
    filter: brightness(0) invert(1);
}

nav ul {
    list-style-type: none;
    display: flex;
    gap: 2rem;
}

nav a {
    text-decoration: none;
    color: var(--menu-text-color);
    font-weight: 500;
    transition: opacity 0.3s ease;
    text-transform: uppercase;
    letter-spacing: 1px;
}

nav a:hover {
    opacity: 0.8;
}

button {
    background-color: var(--button-color);
    color: white;
    /* Change text color to white for better contrast */
    border: none;
    padding: 0.5rem 1rem;
    cursor: pointer;
    font-weight: 500;
    transition: background-color 0.3s ease, transform 0.1s ease;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-right: 0.5rem;
    /* Add some space between buttons */
    margin-bottom: 0.5rem;
    /* Add some space below buttons */
}

button:hover {
    background-color: var(--button-hover-color);
    transform: translateY(-2px);
    /* Slight lift effect on hover */
}

button:active {
    transform: translateY(0);
    /* Reset position when clicked */
}

main {
    max-width: 1200px;
    margin: 2rem auto;
    padding: 0 1rem;
}

.dashboard-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 2rem;
    width: 100%;
}

.dashboard-content {
    flex: 1;
    max-width: calc(50% - 1rem);
}

.dashboard-stats {
    flex: 1;
    max-width: calc(50% - 1rem);
}

.card {
    background-color: var(--card-bg-color);
    box-shadow: 14px 14px 0 -4px gold, 14px 14px 0 0 black;
    padding: 1.5rem;
    margin-bottom: 2rem;
    border-radius: 8px;
    border: 4px solid black;
    transition: box-shadow 0.3s ease, transform 0.3s ease;
}

.dashboard-stats .card {
    width: 100%;
}

@media (max-width: 1200px) {
    .dashboard-container {
        flex-direction: column;
    }

    .dashboard-content,
    .dashboard-stats {
        max-width: 100%;
    }
}

h1,
h2 {
    color: var(--text-color);
}

table {
    width: 100%;
    border-collapse: collapse;
    color: var(--text-color);
}

th,
td {
    padding: 0.75rem;
    text-align: left;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

th {
    font-weight: 600;
    background-color: rgba(0, 0, 0, 0.05);
}

tr:last-child td {
    border-bottom: none;
}

tr:hover {
    background-color: rgba(0, 0, 0, 0.03);
}

.input-group {
    margin-bottom: 1rem;
}

.input-group label {
    display: block;
    margin-bottom: 0.5rem;
}

.input-group input {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    background-color: #ffffff;
    color: var(--text-color);
}

.button-group {
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
}

.button-group button {
    flex: 1;
}

#approveBtn {
    background-color: var(--secondary-color);
    color: white;
}

#approveBtn:hover {
    background-color: #0052cc;
    /* Darker blue */
}

#mintBtn {
    background-color: var(--primary-color);
    color: white;
}

#mintBtn:hover {
    background-color: #ff1a4d;
    /* Darker pink-red */
}

@media (max-width: 768px) {
    nav {
        flex-direction: column;
        align-items: flex-start;
    }

    nav ul {
        margin-top: 1rem;
        flex-direction: column;
        gap: 0.5rem;
    }

    button {
        margin-top: 1rem;
    }

    .dashboard-container {
        flex-direction: column;
    }

    .card {
        width: 100%;
    }
}

select {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    background-color: #ffffff;
    color: var(--text-color);
}

/* Add these new styles */
.mint-container {
    display: flex;
    justify-content: space-between;
    gap: 2rem;
}

.manage-collateral,
.mint-stablecoins {
    flex: 1;
    min-width: 300px;
}

.manage-collateral {
    order: -1;
    /* This ensures the 'Manage Collateral' card appears on the left */
}

/* Update the existing .card style */
.card {
    background-color: var(--card-bg-color);
    box-shadow: 14px 14px 0 -4px gold, 14px 14px 0 0 black;
    padding: 1.5rem;
    margin-bottom: 2rem;
    border-radius: 8px;
    /* Updated to 8px */
    flex: 1;
    min-width: 300px;
    border: 4px solid black;
    /* Updated border */
    transition: box-shadow 0.3s ease, transform 0.3s ease;
}

.card:hover {
    box-shadow: 16px 16px 0 -4px gold, 16px 16px 0 0 black;
    transform: translate(-2px, -2px);
}

/* Add this media query for responsiveness */
@media (max-width: 768px) {
    .mint-container {
        flex-direction: column;
    }

    .manage-collateral,
    .mint-stablecoins {
        width: 100%;
    }
}

/* Update styles for the NFT card buttons */
.manage-collateral button {
    display: inline-block;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
}

/* Update approve and mint button styles */
#approveBtn,
#mintBtn {
    color: white;
    /* Ensure text is white for these buttons too */
}

/* Add these styles to your existing App.css file */

.user-nfts {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    /* Changed from 6 to 4 */
    gap: 10px;
    margin-top: 20px;
}

.nft-placeholder {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 10px;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s ease;
}

.nft-placeholder:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.nft-placeholder.selected {
    border-color: #007bff;
    box-shadow: 0 0 10px rgba(0, 123, 255, 0.5);
}

.nft-image {
    width: 100%;
    height: auto;
    border-radius: 4px;
}

.nft-placeholder p {
    margin-top: 5px;
    font-size: 0.8em;
}

/* Add these styles for the collection image */
.collection-image {
    max-width: 100%;
    height: auto;
}

.resized-image {
    width: 100px;
    /* Adjust this value as needed */
    height: auto;
}

.get-link {
    color: gold;
    /* Gold color for emphasis */
}

/* Existing styles... */

@media (max-width: 1200px) {
    .user-nfts {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (max-width: 768px) {
    .user-nfts {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 480px) {
    .user-nfts {
        grid-template-columns: repeat(1, 1fr);
    }
}