.bridge-container {
  max-width: 600px;
  margin: 20px auto;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: var(--card-bg-color);
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 14px 14px 0 -4px gold, 14px 14px 0 0 black;
  border: 4px solid black;
}

.bridge-container h2 {
  color: #333;
  margin-bottom: 20px;
  text-align: center;
}

.bridge-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.bridge-form input,
.bridge-form select {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
}
 
.bridge-form button {
  padding: 12px;
  font-size: 16px;
  background-color: #ff3366;;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.bridge-form button:hover {
  background-color: #45a049;
}

.error-message {
  color: #ff0000;
  margin-top: 10px;
  text-align: center;
}

/* Responsive design */
@media (max-width: 768px) {
  .bridge-container {
    width: 90%;
    margin: 10px auto;
  }
}