.strategies-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.strategy-card {
  background-color: var(--card-bg-color);
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 14px 14px 0 -4px gold, 14px 14px 0 0 black;
  border: 4px solid black;
}

.mint-deposit-inputs {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

.mint-deposit-inputs input {
  flex: 1;
  padding: 10px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  font-size: 16px;
}

.mint-deposit-button {
  padding: 10px 20px;
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.mint-deposit-button:hover {
  background-color: var(--button-hover-color);
}

.highlighted-max-mintable {
  color: red;
  font-weight: bold;
  transition: all 0.3s ease;
}