/* Add these styles to your existing Header.css file */

.wallet-controls {
  display: flex;
  align-items: center;
  gap: 10px;
}

.wallet-controls select {
  padding: 5px;
  border-radius: 4px;
  border: 1px solid #ccc;
  background-color: white;
  color: black;
  font-size: 14px;
}

@media (max-width: 768px) {
  .wallet-controls {
    flex-direction: column;
    align-items: flex-start;
  }
}

.connect-wallet-btn {
  background-color: var(--primary-color);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.connect-wallet-btn:hover {
  background-color: var(--button-hover-color);
}

.chain-selector {
  appearance: none;
  background-color: var(--card-bg-color);
  border: 4px solid black;
  border-radius: 4px;
  padding: 10px 30px 10px 10px;
  font-size: 16px;
  color: var(--text-color);
  cursor: pointer;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3E%3Cpath fill='%23000000' d='M10.293 3.293L6 7.586 1.707 3.293A1 1 0 00.293 4.707l5 5a1 1 0 001.414 0l5-5a1 1 0 10-1.414-1.414z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 10px center;
  box-shadow: 4px 4px 0 -1px gold, 4px 4px 0 0 black;
  transition: all 0.3s ease;
}

.chain-selector:hover {
  box-shadow: 6px 6px 0 -1px gold, 6px 6px 0 0 black;
  transform: translate(-2px, -2px);
}

.chain-selector:focus {
  outline: none;
  box-shadow: 6px 6px 0 -1px gold, 6px 6px 0 0 black, 0 0 0 2px var(--primary-color);
}

@media (max-width: 768px) {
  .wallet-controls {
    margin-top: 10px;
  }

  .chain-selector {
    width: 100%;
  }
}