:root {
  --background: #f3f4f6;  /* Slightly darker background */
  --card-background: #ffffff;
  --text-primary: #111827;
  --text-secondary: #6b7280;
  --border-color: #121212;
  --shadow-color: rgba(0, 0, 0, 0.5);
  --accent-color: #6366f1;
  --accent-hover: #4f46e5;
}

body {
  background-color: var(--background);
  color: var(--text-primary);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
}

.dashboard {
  padding: 24px;
  max-width: 1200px;
  margin: 0 auto;
}

.dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.dashboard-header h1 {
  font-size: 24px;
  font-weight: 600;
}

/* Remove the .dashboard-actions styles as we no longer need them */

/* ... (keep the rest of the existing styles) */

/* Add styles for the chart */
.content-card canvas {
  max-width: 100%;
  height: auto;
}

.dashboard-stats {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  gap: 24px;
  margin-bottom: 24px;
}

.stat-card, .content-card {
  background-color: var(--card-background);
  border-radius: 8px;
  padding: 24px;
  box-shadow: 14px 14px 0 -4px gold, 14px 14px 0 0 black;
  border: 4px solid black;
  transition: box-shadow 0.3s ease, transform 0.3s ease;
}

.stat-card:hover, .content-card:hover {
  box-shadow: 16px 16px 0 -4px gold, 16px 16px 0 0 black;
  transform: translate(-2px, -2px);
}

.stat-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.stat-header h3 {
  font-size: 14px;
  font-weight: 500;
  color: var(--text-secondary);
  margin: 0;
}

.icon {
  font-size: 20px;
  color: var(--text-secondary);
}

.stat-value {
  font-size: 32px;
  font-weight: 600;
  margin: 0 0 4px 0;
}

.stat-change {
  font-size: 14px;
  color: var(--text-secondary);
  margin: 0;
}

.dashboard-content {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 24px;
}

.content-card h2 {
  font-size: 18px;
  font-weight: 600;
  margin: 0 0 16px 0;
}

.wide {
  grid-column: span 2;
}

.data-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

.data-table th, .data-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid var(--border-color);
}

.data-table th {
  font-weight: 500;
  color: var(--text-secondary);
}

.activity-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.activity-list li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0;
  border-bottom: 1px solid var(--border-color);
}

.activity-list li:last-child {
  border-bottom: none;
}

.activity-info {
  display: flex;
  flex-direction: column;
}

.activity-name {
  font-weight: 500;
  margin: 0;
}

.activity-email {
  font-size: 14px;
  color: var(--text-secondary);
  margin: 0;
}

.activity-amount {
  font-weight: 500;
}

@media (max-width: 768px) {
  .dashboard-content {
    grid-template-columns: 1fr;
  }

  .wide {
    grid-column: auto;
  }
}

.dashboard-container {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    width: 100%;
}

.dashboard-content {
    flex: 1;
    width: 50%;
}

.dashboard-stats {
    flex: 1;
    width: 50%;
}

@media (max-width: 1200px) {
    .dashboard-container {
        flex-direction: column;
    }

    .dashboard-content,
    .dashboard-stats {
        width: 100%;
    }
}